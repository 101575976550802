import * as ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter } from "react-router-dom";
import * as React from "react";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
