import axios from "axios";
import { useNavigate } from "react-router-dom";

import { CalculateResponseType, CalculatorResultType } from "../../types/CaculateResponse.type";
import { ChartStatsResponse } from "../../types/CharStats.type";
import { DetailReponseType } from "../../types/DetailResponse.type";
import { JobDetailsType, JobInfoType, JobQuoteResponseType } from "../../types/JobInfo.type";
import { ListResponseType } from "../../types/ListResponse.type";
import { PaginationType } from "../../types/Pagination.type";
import { useAuth } from "../Authentication";
import { makeAuthErrorResponse } from "./AuthErrorResponse";
import { SignInData, SigninId, SignUpData } from "./SigninId";

export const serverBasePath = "https://restportalproxy.braincloudservers.com/v2/";
// export const serverBasePath = "http://localhost:2000/v2/";

// export const xAppId = `${process.env.REACT_APP_XAPPID}`
export const xAppId = process.env.REACT_APP_APP_ID;

export type ResponseType = { data: any; status: number };

export const useServerAPI = () => {
  const navigate = useNavigate();
  const { authTokens, setAuthTokens, getNextPacket } = useAuth();

  const requestQueue: QueuedRequest[] = [];
  let isRequestInProgress = false;

  const getAuthenticatedHeaders = (token?: string) => {
    const sessionToken = sessionStorage.getItem("token");
    if (sessionToken) token = sessionToken;
    return {
      headers: {
        "X-PACKETID": getNextPacket(),
        Authorization: `Bearer ${token ?? authTokens}`,
      },
    };
  };

  const rpp = axios.create({
    baseURL: serverBasePath,
    headers: {
      "X-APPID": xAppId,
      "Content-Type": "application/json",
    },
  });

  const handleAxiosError = (error: any) => {
    console.error("Axios ERROR", error);
  };

  const handleUnexpectedError = (error: any) => {
    console.error("ERROR", error);
  };

  const restoreSession = async (): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      if (authTokens) {
        const restoreToken = { handoffId: authTokens };
        rpp
          .post("authentication/AUTHENTICATE", restoreToken)
          .then((response) => {
            if (response.status === 200) {
              sessionStorage.setItem("token", response.data.token);
              setAuthTokens(response.data.token);
              resolve(response.data.token);
            } else {
              sessionStorage.removeItem("token");
              setAuthTokens(undefined);
              resolve(null);
            }
          })
          .catch((e) => {
            console.error(e);
            sessionStorage.removeItem("token");
            setAuthTokens(undefined);
            resolve(null);
          });
      }
    });
  };

  type ServerReq = {
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
    path: string;
    data?: any;
    try?: number;
  };
  const MAX_REQ_TRIES = 3;

  type QueuedRequest = {
    request: ServerReq;
    token?: string;
    resolve: (value: ResponseType | PromiseLike<ResponseType>) => void;
    reject: (reason?: any) => void;
  };

  const processResponse = async (
    response: ResponseType,
    resolve: (value: any) => void,
    reject: (reason?: any) => void,
    request: ServerReq
  ) => {
    if (response.data?.error) {
      if (response.data?.error.reason_code === 40303) {
        console.debug("ServerAPI: SESSION EXPIRED will see if we have rememberMe set");
        // Session Expired
        if (localStorage.getItem("rememberMe") === "on") {
          console.debug("ServerAPI: SESSION EXPIRED we have rememberMe set, trying to restore now.");
          const newToken = await restoreSession();
          if (newToken) {
            resolve(performRequest(request, newToken));
            return;
          }
        }
      }
      if (response.data?.error.reason_code === 40566) {
        // Out of order pkt, try
        console.warn(`ServerAPI: Out of order packet detected, on try ${request.try} for ${request.path}`);
        if (request.try && request.try < MAX_REQ_TRIES) {
          console.info(`ServerAPI: Re-trying packet for ${request.path}`);
          resolve(performRequest(request));
          return;
        }
      }
      console.error("Got an error willprocess it....", response.data?.error);
      if (response.data?.error.status === 403) {
        setAuthTokens(undefined);
        sessionStorage.removeItem("token");
        navigate("/login", {
          state: { error: makeAuthErrorResponse(response.data.error) },
        });
      } else if (response.data?.error.status === 401) {
        setAuthTokens(undefined);
        sessionStorage.removeItem("token");
        navigate("/login", {
          state: { error: makeAuthErrorResponse(response.data.error) },
        });
      } else if (response.data?.error.status === 423) {
        setAuthTokens(undefined);
        sessionStorage.removeItem("token");
        navigate("/login", {
          state: { error: makeAuthErrorResponse(response.data.error) },
        });
      } else {
        reject(makeAuthErrorResponse(response.data.error));
      }
    } else {
      // console.debug(`ServerAPI: processResponse:  did receive an answer ${response.status}`, response.data);
      resolve(response);
    }
  };

  const performRequest = async (request: ServerReq, token?: string): Promise<ResponseType> => {
    var { method, path, data } = request;
    if (request.try) {
      request.try += 1;
      console.warn(`ServerAPI: Performing retry for packet number ${request.try} for ${request.path} .....`);
    } else request.try = 1;

    console.debug(`ServerAPI: performRequest: ${method} on ${path} with data ....`, data);
    return new Promise(async (resolve, reject) => {
      var cacheBusterPath = path + (path.includes("?") ? "&cb=" : "?cb=") + `${Math.random() * 10000}`;
      try {
        isRequestInProgress = true;
        var response: any;
        switch (method) {
          case "GET":
            response = await rpp.get(path, getAuthenticatedHeaders(token));
            break;
          case "POST":
            response = await rpp.post(cacheBusterPath, data, getAuthenticatedHeaders(token));
            break;
          case "PUT":
            response = await rpp.put(path, data, getAuthenticatedHeaders(token));
            break;
          case "PATCH":
            response = await rpp.patch(path, data, getAuthenticatedHeaders(token));
            break;
          case "DELETE":
            response = await rpp.delete(path, getAuthenticatedHeaders(token));
            break;
        }
        processResponse(response, resolve, reject, request);
        // Process the next request in the queue, if any
        if (requestQueue.length > 0) {
          const nextRequest = requestQueue.shift();
          if (nextRequest) console.debug(`Processing next queued network request ${nextRequest.request.path}`);
          if (nextRequest)
            performRequest(nextRequest.request, nextRequest.token).then(nextRequest.resolve).catch(nextRequest.reject);
        } else {
          isRequestInProgress = false;
        }
      } catch (error) {
        console.error("ServerAPI: -------------error", error);
        if (axios.isAxiosError(error)) {
          handleAxiosError(error);
          if (error.response?.status === 423) resolve({ status: 423, data: {} });
        } else {
          handleUnexpectedError(error);
        }
      }
    });
  };

  const submitRequest = async (request: ServerReq, token?: string): Promise<ResponseType> => {
    return new Promise((resolve, reject) => {
      const qRequest: QueuedRequest = {
        request,
        token,
        resolve,
        reject,
      };
      if (!isRequestInProgress) {
        // If no request is currently being processed, execute the request immediately
        performRequest(request, token).then(resolve).catch(reject);
      } else {
        // Add the request to the queue
        console.debug(`Queueing Network request for later ${request.path}`);
        requestQueue.push(qRequest);
      }
    });
  };

  return {
    // ************************************************
    // Unauthneticated calls
    // ************************************************

    signIn: (credentials: SignInData) => {
      // reset packet id at login.
      // sessionStorage.setItem("X-PACKETID", "0");
      return rpp.post("authentication/AUTHENTICATE", credentials, { headers: { "x-error-output": "ext" } });
    },

    resetEmailPassword: (data: SigninId) => performRequest({ path: "authentication/FORGOT", method: "POST", data }),

    signUp: async (data: SignUpData) => {
      const signupResult = await performRequest({
        path: "authentication/SIGNUP",
        method: "POST",
        data,
      });
      console.debug(`Signup: results`, signupResult);
      if (signupResult.status === 200) {
        setAuthTokens(signupResult.data.token);
        // Call script to register extra data.
        const { password, ...userDetails } = data;
        return await performRequest({
          path: "script/RUN/UpdateUserDetails",
          method: "POST",
          data: userDetails,
        });
      }
      return;
    },

    // ************************************************
    // Authneticated calls
    // ************************************************

    calculateChannelBlender: async (data: any, jobInfo: JobInfoType): Promise<CalculateResponseType<CalculatorResultType>> => {
      const requestData = { action: "calculate_options", input: data, jobInfo };
      const result = await performRequest({
        path: "script/RUN/ChannelCalculatorWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: calculateChannelBlender return`, result.data.data.response);
        return result.data.data.response;
      }
      return {
        error: { Status: "Error", StatusMessage: "no results" },
        options: [],
        result: { Status: "Error", StatusMessages: ["no results"] },
        entityId: "",
        jobInfo: {} as JobInfoType,
      };
    },

    getChannelBlenderDefaults: async (data: any) => {
      const requestData = { action: "values", input: data };
      const result = await performRequest({
        path: "script/RUN/ChannelCalculatorWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: getChannelBlenderDefaults return`, result);
        if (result.data.data.response.labels) return result.data.data.response;
        return { data: result.data.data.response };
      }
      return { data: {} };
    },

    getJobList: async (
      pagination?: PaginationType,
      summaryOnly?: boolean
    ): Promise<ListResponseType<CalculateResponseType>> => {
      const requestData = { action: summaryOnly ? "list_summary" : "list", pagination };
      const result = await performRequest({
        path: "script/RUN/JobInfoWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: getJobList return`, result);
        return result.data.data.response;
      }
      return { items: [], count: 0, page: 1 };
    },

    getJobDetail: async (entityId: string): Promise<DetailReponseType<JobDetailsType> | null> => {
      const requestData = { action: "get_job", entityId };
      const result = await performRequest({
        path: "script/RUN/JobInfoWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: getJobDetail return`, result.data.data.response);
        return result.data.data.response;
      }
      return { error: { status_message: "Could not retrieve Job Detail", reason_code: 0, status: 500 } };
    },

    getJobInsight: async (forDate?: Date): Promise<ChartStatsResponse> => {
      const requestData = { action: "stats", forDate };
      const result = await performRequest({
        path: "script/RUN/JobInfoWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: getJobInsight return`, result);
        return result.data.data;
      }
      return { response: [], success: false };
    },

    selectJobForQuote: async (
      currententityId: string,
      selectedEntityId: string,
      quotedPrice?: number
    ): Promise<DetailReponseType<JobQuoteResponseType>> => {
      const requestData = { action: "request_quote", entityId: currententityId, selectedEntityId, quotedPrice };
      const result = await performRequest({
        path: "script/RUN/JobInfoWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: selectJobForQuote return`, result);
        return result.data.data.response;
      }
      return { error: { status_message: "Could Request Job Quote", reason_code: 0, status: 500 } };
    },

    selectJobFor3dStepFile: async (currententityId: string, selectedEntityId: string): Promise<any> => {
      const requestData = { action: "request_stepfiles", entityId: currententityId, selectedEntityId };
      const result = await performRequest({
        path: "script/RUN/JobInfoWeb",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: selectJobForQuote return`, result);
        return result.data.data;
      }
      return { error: { status_message: "Could Request Job Quote", reason_code: 0, status: 500 } };
    },

    performRequest: submitRequest,

    /**
     *
     * @returns { data: {
     *                success: true,
     *                response: {
     *                  profile: ...
     *                }
     *              }
     *          }
     */
  };
};
