export const isEmpty = (data: any): data is undefined => {
  if (data === null || data === undefined) return true;
  if (Array.isArray(data)) return (data.length === 0);
  if (typeof data === 'string' && data.length > 0) return false;
  if (typeof data === 'number') return false;
  if (typeof data === 'boolean') return false;
  if (typeof data === 'object' && data !== null && Object.keys(data).length > 0) return false;
  return true;
};

export const getPropertyByPath = (obj: Record<string, any>, path: string): any | undefined => {
  const pathArray = path.split('.');
  let current = obj;

  for (const key of pathArray) {
    if (current && current.hasOwnProperty(key)) {
      current = current[key];
    } else {
      return undefined;
    }
  }

  return current;
}


export const areUnOrderedArraysEquivalent =<T extends Record<string,any>>( arr1:T[], arr2: T[]):boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every(item1 => arr2.find(item2=> {
    return areObjectsEqual(item1,item2)
  }));
}

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Different number of properties, objects are not equal
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (!areObjectsEqual(obj1[key], obj2[key])) {
          return false; // Nested objects are not equal
        }
      } else {
        return false; // Property values are not equal
      }
    }
  }

  return true; // All properties are equal
}

export const areOrderedArraysEquivalent = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false; // Arrays are of different lengths, not equivalent
  }
  for (let i = 0; i < arr1.length; i++) {
    if (!areObjectsEqual(arr1[i], arr2[i])) {
      return false; // Objects at index i are not equal, arrays are not equivalent
    }
  }
  return true; // All objects in arrays are equal, arrays are equivalent
}