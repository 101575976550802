import React from "react";

import { Navigate, Outlet } from "react-router-dom";

import { Box } from "@mui/system";

import { useAuth } from "./Authentication";

const ProtectedRoutes = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return (
      <Box sx={{ backgroundColor: "inherit", height: "100%", width: "100%" }}>
        <Box sx={{ backgroundColor: "inherit" }}>
          <Outlet />
        </Box>
      </Box>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoutes;
