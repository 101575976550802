export interface ErrorMessageObj {
  status_message: string;
  reason_code: number;
  status: number;
  severity?: string;
}

export interface AuthErrorResponse {
  statusCode: number;
  name: string;
  message: string; // This is the stringified ErrorMessageObj.
}

export const makeAuthErrorResponse = (error: ErrorMessageObj): AuthErrorResponse => {
  var msg = error.status_message;
  if (
    error.status_message &&
    error.status_message.startsWith("Session expiry exception (bundle): user session has expired:")
  ) {
    msg = "Session expired. Please log in again";
  }
  return {
    statusCode: error.status,
    name: error.severity ?? "ERROR",
    message: msg,
  };
};
