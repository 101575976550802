import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import {Box, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {styled} from "@mui/system";

import {CalculateResponseType} from "../../types/CaculateResponse.type";
import {toLocalDate} from "../../utils/DateConversion";
import {useServerAPI} from "../../utils/Session";

const TableCell = styled(MuiTableCell)(({theme}) => ({
  border: "none", // Remove the borders
  padding: "14px",
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontFamily: "Montserrat Bold",
  fontSize: "11px",
}));

const StyledTableCell = styled(MuiTableCell)(({theme}) => ({
  border: "none", // Remove the borders,
  fontFamily: "Montserrat Bold", // Change the font family
  fontSize: "11px", // Change the font size
  color: theme.palette.text.secondary,
}));

const RecentJobs = () => {
  const navigate = useNavigate();
  const serverApi = useServerAPI();
  const [jobs, setjobs] = useState<CalculateResponseType[]>();

  useEffect(() => {
    const getJobList = () => {
      serverApi.getJobList({pageNumber: 1, rowsPerPage: 5}, true).then((result) => {
        if (result.items && Array.isArray(result.items)) {
          const jobInfoList = result.items;
          setjobs(jobInfoList);
          console.log(`Recent jobs results are`, jobInfoList);
        }
      });
    };

    if (!jobs) getJobList();
  }, []);

  const handleOnJobClick = (entityId: string) => {
    navigate("/ChannelBlenderJob", {state: {entityId: entityId}});
  };

  return (
    <Box sx={{height: "100%", display: "flex", flexDirection: "column"}}>
      <Typography variant='h5' gutterBottom>
        Recent Jobs
      </Typography>
      <Paper variant='elevation' elevation={2} sx={{padding: "0 15px", height: "100%"}}>
        <Table aria-label='recent jobs table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align='left'>Name</StyledTableCell>
              <StyledTableCell align='left'>Type</StyledTableCell>
              <StyledTableCell align='left'>Date</StyledTableCell>
              <StyledTableCell align='left'>Job Location</StyledTableCell>
              <StyledTableCell align='left'>AHU tag</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {(jobs ?? []).map((row) => (
              <TableRow key={row.jobInfo.name} sx={{border: 0}}>
                <TableCell component='th' scope='row' align='left'>
                  <Typography
                    color={"primary"}
                    sx={{cursor: "pointer"}}
                    onClick={() => {
                      if (row.entityId) handleOnJobClick(row.entityId);
                    }}>
                    {row.jobInfo.name}
                  </Typography>
                </TableCell>
                <TableCell align='left'>{row.jobInfo.type}</TableCell>
                <TableCell align='left'>{toLocalDate(row.jobInfo.date)}</TableCell>
                <TableCell align='left'>{row.jobInfo.jobLocation}</TableCell>
                <TableCell align='left'>{row.jobInfo.ahuTag}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default RecentJobs;
