import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import theme from "../../theme";
import Insights from "./Insights";
import RecentJobs from "./RecentJobs";
import SelectTool from "./SelectTool";
import { useAuth } from "../../utils/Authentication";

const GridItem = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const Dashbaord = () => {
  const { userData } = useAuth();

  const showAirBlender = userData?.enableAirBlender;

  return (
    <Box sx={{ padding: "24px" }}>
      <Grid container rowGap={4}>
        <Grid
          container
          justifyContent='space-around'
          spacing={4}
          rowGap={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
            },
          }}
        >
          <GridItem item md={showAirBlender ? 5 : 3}>
            <SelectTool />
          </GridItem>
          <GridItem item md={showAirBlender ? 7 : 9}>
            <RecentJobs />
          </GridItem>
        </Grid>
        <Grid container>
          <Insights />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashbaord;
