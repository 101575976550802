import React from "react";

import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import { Box, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";

import { useAuth } from "../../utils/Authentication";
import airBlenderImage from "../../assets/airBlender.png";
import channelBlenderImage from "../../assets/channelBlender.png";

const SelectTool = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();

  const showAirBlender = userData?.enableAirBlender;

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant='h5' gutterBottom>
        Select Tool
      </Typography>
      <Card variant='elevation' elevation={2}>
        <CardContent>
          <Grid container alignItems='center' justifyContent={"space-between"}>
            <Grid item xs>
              <CardMedia component='img' height='225' width='220' image={channelBlenderImage} alt='Channel Blender' />
              <Button
                variant='contained'
                color='primary'
                fullWidth
                sx={{ mt: "15px" }}
                onClick={() => navigate("/ChannelBlenderJob")}
              >
                Channel Blender
              </Button>
            </Grid>
            {showAirBlender && (
              <>
                <Divider orientation='vertical' flexItem sx={{ m: "2px 5%" }} />
                <Grid item xs>
                  <CardMedia component='img' height='225' width='220' image={airBlenderImage} alt='Air Blender' />
                  <Button variant='contained' color='primary' fullWidth sx={{ mt: "15px" }}>
                    Air Blender
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SelectTool;
