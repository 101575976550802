import React, { ReactNode, useState } from "react";

import { toast } from "react-toastify";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  GridProps,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import MainLogoTransparent from "../../assets/mainLogo-transparent.png";
import { JobDetailsType, JobInfoType, JobStateType } from "../../types/JobInfo.type";
import { useServerAPI } from "../../utils/Session";
import ReactGA from "react-ga4";
import { LabelType } from "../../types/Label.type";
import { CalculatorLabelsType } from "../../types/CalculatorLabel.type";
import { getPropertyByPath } from "../../utils/objectUtils";
import SummaryDialog from "../../components/SummaryDialog";


interface PROPS {
  jobState: JobStateType | null;
  results: any[];
  jobInfo?: JobInfoType;
  onJobStateChange: (jobState: JobStateType | null) => void;
  onDataUpdate: (jobInfo: JobInfoType, calculatedResult: any, entityId?: string, options?: JobDetailsType[]) => void;
  channelBlenderLabels?: CalculatorLabelsType;
}

interface CustomGridItemProps {
  isSelected: boolean;
  borderLeftTransparent: boolean;
  borderRightTransparent: boolean;
}


const labels: LabelType[] = [
  { id: "Header", label: "Channel Blender", header: true },
  { id: "ModelID", label: "Model ID" },

  { id: "ChannelBlenderQty", label: "Quantity" },
  { id: "RequiredActuatorQty", label: "Required Actuators" },
  {
    id: "ListPrice",
    label: "Est. Price\n ($)",
    currency: "USD",
    tip: "Prices shown are list prices and are subject to discount and to change without notice. Please contact Blender Products inc. for most recent pricing information to be used for contracting purposes.",
  },
  {
    id: "PressureDrop25pcOA",
    label: 'Pressure Drop 1\n (25% OA, "w.c.)',
    tip: '± 0.05" W.C. Estimate is not guaranteed. Final pressure drop figures to be supplied by blender products after analysis has been performed.',
  },
  {
    id: "PressureDrop100pcOA",
    label: 'Pressure Drop 2\n (100% OA, "w.c.)',
    tip: '± 0.05" W.C. Estimate is not guaranteed. Final pressure drop figures to be supplied by blender products after analysis has been performed.',
  },
  { id: "Weight", label: "Weight\n (lbs)", tip: "Weight estimate is ± 10%" },
  { id: "Width", label: "Dimension X\n (inches)" },
  { id: "Height", label: "Dimension Y\n (inches)" },
  { id: "Depth", label: "Dimension Z\n (inches)" },
  {
    id: "MixingBoxDepth",
    label: "Mixing Distance\n (inches)",
    tip: "Recommended distance from the back wall of the mixing box to the upstream filter face.",
  },
  { id: "HeaderOA", label: "Outside Air Opening", header: true },
  { id: "OAOpeningWidth", label: "OA1\n (inches)" },
  { id: "OAOpeningLength", label: "OA2\n (inches)" },
  { id: "OAInletVelocity", label: "OA Inlet Velocity\n (cfm)" },

  { id: "HeaderRA", label: "Return Air Opening", header: true },
  { id: "RAOpeningWidth", label: "RA1\n (inches)" },
  { id: "RAOpeningLength", label: "RA2\n (inches)" },
  { id: "RAInletVelocity", label: "RA Inlet Velocity\n (cfm)" },

  { id: "HeaderPos", label: "Opening Positions", header: true },
  { id: "A", label: "A\n (inches)" },
  { id: "B", label: "B\n (inches)" },
  { id: "C", label: "C\n (inches)" },

  // { id: "", label: "Est. Savings / Year" },
  // { id: "", label: "Economizing Hours / Year" }, hiding these fileds until we receive the savings data in the repsonse
];

interface CustomGridItemProps extends GridProps {
  isFirst: boolean;
  isLast: boolean;
  isSelected: boolean;
  borderLeftTransparent: boolean;
  borderRightTransparent: boolean;
  children?: ReactNode;
}

const CustomGridItem: React.FC<CustomGridItemProps> = ({
  isFirst,
  isLast,
  isSelected,
  borderLeftTransparent,
  borderRightTransparent,
  children,
  ...otherProps
}) => {
  const theme = useTheme();

  const style = {
    border: isSelected ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.divider}`,
    borderLeft: borderLeftTransparent
      ? "none"
      : isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : `2px solid ${theme.palette.divider}`,
    borderRight: borderRightTransparent
      ? "none"
      : isSelected
        ? `2px solid ${theme.palette.primary.main}`
        : `2px solid ${theme.palette.divider}`,
    boxShadow: "initial",
    borderTopLeftRadius: isFirst ? "6px" : "initial",
    borderBottomLeftRadius: isFirst ? "6px" : "initial",
    borderTopRightRadius: isLast ? "6px" : "initial",
    borderBottomRightRadius: isLast ? "6px" : "initial",
    backgroundColor: isSelected ? "#ebfaff" : "transparent",
    paddingTop: "20px",
  };

  return (
    <Grid style={style} {...otherProps}>
      {children}
    </Grid>
  );
};

const Results = (props: PROPS) => {
  const { results = [], jobState, jobInfo, channelBlenderLabels } = props;

  const serverAPI = useServerAPI();

  const [selectedOptionId, setSelectedOptionId] = React.useState<string>("");
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [printPreview, setPrintPreview] = useState<boolean>(false);
  // const { userData } = useAuth();

  // const componentRef = useRef(null);


  // const handlePrint = useReactToPrint({
  //   documentTitle: "Blender Preliminary Design",
  //   removeAfterPrint: true,
  // });



  const handleOptionSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? event.target.value : undefined;
    setSelectedOptionId(newValue ?? "");
  };

  const selectJobForQuote = async () => {
    setIsBusy(true);
    try {
      const quotedPrice = results.find((result) => result.entityId)?.ListPrice;
      const response = await serverAPI.selectJobForQuote(results[0].entityId, selectedOptionId, quotedPrice);
      if ("jobInfo" in response) {
        props.onDataUpdate(response.jobInfo, response.result, results[0].entityId);
        props.onJobStateChange(response.jobInfo?.jobState ?? null);
        if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
          ReactGA.event({
            category: "Quotes",
            action: "Request",
            label: response.result.ModelID, // optional
            transport: "xhr", // optional, beacon/xhr/image
          });
        }
      } else {
        toast.error(response.error?.status_message);
      }
      setIsBusy(false);
      toast.success("A quote will be sent to your email address shortly.");
    } catch (e: any) {
      setIsBusy(false);
      toast.error(e?.message);
    }
  };

  const valueFormatter = (label: typeof labels[0], result: any) => {
    var value = getPropertyByPath(result, label.id) ?? null;
    if (value != null) {
      if (typeof value === "number") {
        if (label.currency) value =
          currencyFormatter(value, label.currency)
        else value = formatNumberWithDecimals(value, 2)
        // } else {
        // value = result[label.id]
      } if (typeof value === 'boolean') {
        value = value ? 'Yes' : 'No'
      }
    } else {
      if (label.header)
        value = <Box sx={{ height: "56px", }}><Typography sx={{ borderBottom: "1px solid lightGray" }} ><div style={{ visibility: 'hidden' }}>---</div></Typography></Box>
      else
        value = "---"
    }
    return value
  }


  // const selectJobFor3DStepFile = async () => {
  //   setIsBusy(true);
  //   try {
  //     await serverAPI.selectJobFor3dStepFile(results[0].entityId, selectedOptionId);
  //     setIsBusy(false);
  //     toast.success("A file will be sent to your email address shortly.");
  //   } catch (e: any) {
  //     setIsBusy(false);
  //     toast.error(e?.message);
  //   }
  // };
  return (
    <Grid container>
      <Grid item display={"flex"} alignItems={"center"} sx={{ cursor: "pointer" }}>
        <Typography variant='h5'>Results</Typography>
      </Grid>
      <Paper
        sx={{
          padding: "24px",
          width: "100%",
          gap: 20,
          marginTop: "20px",
          display: "flex",
          minHeight: "696px",
        }}>
        {results.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "696px",
              width: "100%",
            }}>
            <img
              src={MainLogoTransparent}
              alt={"No results logo"}
              style={{ height: "113px", width: "113px", alignSelf: "center" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography variant='h5' padding={"10px 0px"}>
                No Results Yet.
              </Typography>

              <Typography>
                Enter job parameters and click the <b>Calculate Results</b>
              </Typography>

              <Typography>button to view our recommendations</Typography>
            </Box>
          </Box>
        )}
        {results.length > 0 && (
          <Grid container width={"100%"} rowGap={3}>
            {results.length > 1 && (
              <Grid justifyContent={"flex-end"} container>
                {results.map((result: any, i) => {
                  return (
                    <Grid item xs={10 / results.length} key={`option${i}`}>
                      <Typography variant='h5' align='center'>
                        {`Option ${i + 1}`}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {results[0].status === "Manual" ? (
              <Typography variant='h6' sx={{ whiteSpace: "pre" }} key="M">
                {results[0].statusMessage}
              </Typography>
            ) : (
              <Grid container spacing={1} alignItems={"baseline"} key="A">
                <Grid item xs={3} paddingTop={"0px !important"} alignContent={'flex-start'}>
                  {/* Display the labels in the left column */}
                  {labels.map((label) => (
                    label.header ?
                      <Box sx={{ height: "56px", }} key={label.id}><Typography sx={{ borderBottom: "1px solid lightGray" }} >{label.label}</Typography></Box>
                      : <Typography
                        key={label.id}
                        variant={'body1'}
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          whiteSpace: "pre",
                          height: "56px",
                          alignSelf: "center",
                        }}
                        color={"GrayText"}>
                        {label.label}
                      </Typography>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{
                    marginBottom: "-1px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0px 0px 0px",
                  }}>
                  {results.map((result: any, i) => {
                    const selectedOptionIndex = results.findIndex((item) => item.entityId === selectedOptionId);
                    const borderRightTransparent =
                      i < results.length - 1 &&
                      (selectedOptionIndex > -1 ? selectedOptionIndex - i === 1 || selectedOptionIndex !== i : true);

                    const borderLeftTransparent = i > 0 && selectedOptionIndex > -1 && i - selectedOptionIndex === 1;
                    const isSelected = selectedOptionIndex === i;
                    const isFirst = i === 0;
                    const isLast = i === results.length - 1;
                    return (
                      <CustomGridItem
                        item
                        xs={12 / results.length}
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        key={i}
                        isFirst={isFirst}
                        isLast={isLast}
                        isSelected={isSelected}
                        borderLeftTransparent={borderLeftTransparent}
                        borderRightTransparent={borderRightTransparent}
                        padding={""}>
                        {labels.map((label) => (
                          label.header ? <Box key={label.id} sx={{ minHeight: '1em' }} ><Box sx={{ height: "56px", width: "100%" }} ><Box sx={{ borderBottom: "1px solid lightGray" }} ><span style={{ visibility: 'hidden' }}>---</span></Box></Box></Box> :
                            <Typography
                              key={label.id}
                              sx={{
                                height: "56px",
                                alignSelf: "center",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                                width: "100%",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                boxSizing: "border-box",
                              }}>
                              <Tooltip title={result[label.id] != null ? result[label.id] : ""} arrow>
                                <Typography component={"span"} sx={{ minHeight: label.header ? '1em' : undefined }}>
                                  {valueFormatter(label, result)}
                                  {/* {result[label.id] != null
                                  ? typeof result[label.id] === "number"
                                    ? label.currency
                                      ? currencyFormatter(result[label.id], label.currency)
                                      : formatNumberWithDecimals(result[label.id], 2)
                                    : result[label.id]
                                  : label.header ? <hr /> : "---"} */}
                                </Typography>
                              </Tooltip>
                            </Typography>
                        ))}
                        {result.status !== "Error" && (
                          <Box
                            padding={"12px"}
                            sx={{
                              borderTop: (theme) =>
                                isSelected
                                  ? `1px solid ${theme.palette.primary.main}`
                                  : `1px solid ${theme.palette.divider}`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottomLeftRadius: isFirst ? "6px" : "initial",
                              borderBottomRightRadius: isLast ? "6px" : "initial",
                              backgroundColor: (theme) => theme.palette.background.default,
                            }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedOptionId === result.entityId}
                                  onChange={handleOptionSelection}
                                  value={result.entityId}
                                  color='primary'
                                />
                              }
                              label={<Typography color={"primary"}>Select</Typography>}
                            />
                          </Box>
                        )}
                      </CustomGridItem>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {(results[0].status === "Valid" || results[0].status === "Warning") && (
              <Grid container direction='row-reverse' alignItems='flex-end' spacing={2}>
                {/* <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    disabled={isBusy || !selectedOptionId}
                    onClick={selectJobFor3DStepFile}
                  >
                    Request 3D Step File
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    disabled={isBusy || !selectedOptionId}
                    onClick={() => setPrintPreview(true)}>
                    Print Preview
                  </Button>
                </Grid>
                {jobState !== "Accepted" && (
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      disabled={isBusy || !selectedOptionId}
                      onClick={selectJobForQuote}>
                      Request Quote
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Paper>

      <SummaryDialog
        open={printPreview}
        onClose={() => setPrintPreview(false)}
        jobInfo={jobInfo}
        jobDetail={results.find(result => selectedOptionId === result.entityId)}
        // labels={labels} 
        valueFormatter={valueFormatter}
        channelBlenderLabels={channelBlenderLabels}
      />
    </Grid>
  );
};

export default Results;

function formatNumberWithDecimals(number: number, decimalPlaces: number) {
  const multiplier = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.round(number * multiplier) / multiplier;
  return roundedNumber.toLocaleString();
}

export const currencyFormatter = (data?: string | number, currency: string = "CAD"): string => {
  if (data === null || data === undefined) return "";
  console.debug(`Using currency formater for value ${data}`);
  const options: Intl.NumberFormatOptions = { style: "currency", currency: currency, currencyDisplay: "narrowSymbol" };
  // const options = {style: "currency", currency: currency};

  const currencyFormat = new Intl.NumberFormat("en-US", options);
  const amount = typeof data === "string" ? parseFloat(data) : data;

  return currencyFormat.format(amount);
};
