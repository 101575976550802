import * as React from "react";

import { ToastContainer } from "react-toastify";
import { Route, Routes, useLocation } from "react-router-dom";

import { Box, Divider, ThemeProvider } from "@mui/material";

import theme from "./theme";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import Dashbaord from "./pages/Dashboard";
import LoginPage from "./pages/Login/LoginPage";
import ProtectedRoutes from "./utils/ProtectedRoute";
import CreateAccount from "./components/CreateAccount";
import { Authentication } from "./utils/Authentication";
import ChannelBlenderJob from "./pages/ChannelBlenderJob";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import ResetPassword from "./components/ResetPassword";
import ReactGA from "react-ga4";

export default function App() {
  const location = useLocation();

  if (process.env.REACT_APP_GA_MEASUREMENT_ID) ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

  const isLoginPage = ["/login", "/ResetPassword", "/CreateAccount"].includes(location.pathname);

  return (
    <Authentication>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh", // Ensure at least the full viewport height
            backgroundColor: "#fbfbfb",
          }}
        >
          <Box
            sx={{
              flex: "1", // Allow the main content to grow
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppBar></AppBar>
            <Routes>
              <Route path='/' element={<ProtectedRoutes />}>
                <Route path='/' element={<Dashbaord />} />
                <Route path='/ChannelBlenderJob' element={<ChannelBlenderJob />} />
              </Route>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/CreateAccount' element={<CreateAccount />} />
              <Route path='/ResetPassword' element={<ResetPassword />} />
            </Routes>
          </Box>
          {!isLoginPage && <Divider />}
          <Footer />
        </Box>
        {/* React Toastify Container. */}
        <ToastContainer
          position={"top-right"}
          autoClose={5000}
          closeOnClick={false}
          pauseOnHover={true}
          pauseOnFocusLoss={true}
          hideProgressBar={false}
          newestOnTop={false}
        />
      </ThemeProvider>
    </Authentication>
  );
}
