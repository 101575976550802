import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0079c2",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fbfbfb",
    },
  },
  typography: {
    fontFamily: "Montserrat Regular",
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: "34px",
          height: "34px",
          maxHeight: "34px",

          overflow: "visible",

          fontFamily: "inherit",
        },
        input: {
          cursor: "inherit",

          "&:-webkit-autofill": {
            height: 0,
          },

          "&[type=number]": {
            MozAppearance: "textfield",
          },

          "&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },

          "&.Mui-disabled": {
            WebkitTextFillColor: "unset",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Set textTransform to "none" to prevent capitalization
          fontSize: "16px",
          borderRadius: 6,
        },
      },
    },
  },
});

export default theme;
