import React, { createContext, ReactNode, useContext, useState } from "react";

import { jwtDecode, JwtPayload } from "jwt-decode";

import { User } from "../../types/User.type";

export type CustomJwtPayload = JwtPayload & {
  authPayload?: User;
};

const decodeAuthToken = (token: any) => {
  try {
    const decodedToken = jwtDecode<CustomJwtPayload>(token);
    return decodedToken;
  } catch (error) {
    return null;
  }
};
type AuthenticationProps = {
  children: ReactNode;
};

export const Authentication: React.FC<AuthenticationProps> = ({ children }) => {
  const [authTokens, setAuthTokens] = useState<string | undefined>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [userData, setUserData] = useState<any | null>(null);

  const setTokens = (data?: string) => {
    if (data !== undefined) {
      sessionStorage.setItem("token", data);
      const decodedToken = decodeAuthToken(data);
      if (decodedToken?.authPayload) {
        setUserData(decodedToken.authPayload as User);
      } else {
        setUserData(null);
      }
    } else {
      logout();
    }
    setAuthTokens(data);
  };

  if (firstLoad) {
    const savedToken = sessionStorage.getItem("token");
    if (savedToken) {
      console.debug("Found Token in Session Storage, Restoring session", savedToken);
      setTokens(savedToken);
      const decodedToken = decodeAuthToken(savedToken);
      if (decodedToken?.authPayload) {
        setUserData(decodedToken.authPayload as User);
      } else {
        setUserData(null);
      }
    }
    setFirstLoad(false);
  }

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("X-PACKETID");
    setAuthTokens(undefined);
    setUserData(null);
  };

  const getNextPacket = (): number => {
    var pktid = parseInt(sessionStorage.getItem("X-PACKETID") ?? "0");
    const newpacketId = pktid + 1;
    sessionStorage.setItem("X-PACKETID", `${newpacketId}`);
    return newpacketId;
  };

  const initialContext = {
    authTokens,
    userData,
    setAuthTokens: setTokens,
    logout: logout,
    isAuthenticated: () => {
      return authTokens == null ? false : true;
    },
    getNextPacket: getNextPacket,
  };

  return <AuthContext.Provider value={initialContext}>{children}</AuthContext.Provider>;
};

export default Authentication;

export const AuthContext = createContext<{
  authTokens?: string;
  userData: User | null;
  setAuthTokens: Function;
  logout: Function;
  isAuthenticated: Function;
  getNextPacket: Function;
}>({
  userData: null,
  setAuthTokens: () => {},
  logout: () => {},
  isAuthenticated: () => false,
  getNextPacket: () => 0,
});

export function useAuth() {
  return useContext(AuthContext);
}
