import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {CaculatorValueType} from "../types/CaculatorValue.type";
import {CalculatorLabelType} from "../types/CalculatorLabel.type";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type FormData = {
  [key: string]: {
    type: string;
    value: any;
  };
};

type ImageSelectorProps = {
  field: CaculatorValueType;
  calculatorFormData: FormData;
  formErrors: any;
  label: CalculatorLabelType;
  isReadOnly?: boolean;
  onChange?: (id: string, value: any) => void;
};

export const ImageSelector = ({
  field,
  calculatorFormData,
  label,
  formErrors,
  isReadOnly,
  onChange,
}: ImageSelectorProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const didSelectItem = (value: string) => {
    console.log(`For field ${field.id} selected value ${value}`);
    if (onChange) {
      onChange(field.id, value);
      if (inputRef?.current?.selectionEnd) inputRef.current.selectionEnd = 0;
    }
    setOpen(false);
  };

  const keyDownHandler: React.KeyboardEventHandler<HTMLDivElement> = ({nativeEvent, code}) => {
    if (code.toLowerCase() === "enter") {
      setOpen(!isReadOnly);
      nativeEvent.preventDefault();
    }
  };

  return (
    <>
      {/* <ScrollingTextField */}
      <TextField
        id={field.id}
        inputRef={inputRef}
        name={field.id}
        value={label[calculatorFormData[field.id].value].label}
        type={"text"}
        fullWidth
        sx={{cursor: "pointer"}}
        onSelect={() => {
          if (inputRef?.current?.scrollLeft) inputRef.current.scrollLeft = 0;
        }}
        onClick={() => setOpen(!isReadOnly)}
        variant='outlined'
        error={formErrors[field.id] ? true : false}
        helperText={formErrors[field.id]}
        onKeyDown={keyDownHandler}
        onBlur={() => {
          if (inputRef?.current?.scrollLeft) inputRef.current.scrollLeft = 0;
        }}
        InputProps={{
          readOnly: true,
          style: {cursor: "pointer"},
          endAdornment: !isReadOnly && <ArrowDropDownIcon sx={{marginRight: "-5px", cursor: "pointer"}} />,
        }}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{field.label}</DialogTitle>
        <DialogContent>
          <ImageList cols={3}>
            {Object.keys(label).map((item) => (
              <ImageListItem key={item} onClick={() => didSelectItem(item)}>
                <img
                  style={{
                    objectFit: "contain",
                    alignSelf: "center",
                    marginTop: "50px",
                  }}
                  src={`${label[item].boxImg}`}
                  loading='lazy'
                />
                <ImageListItemBar title={label[item].label} position='top' />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
