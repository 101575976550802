

export const CurrencyFormatter = (data?: string | number): string => {

  if (data === null || data === undefined) return "";

  const options = { style: 'currency', currency: 'CAD', currencyDisplay: "narrowSymbol" };

  const currencyFormat = new Intl.NumberFormat('en-CA', options);
  const amount = typeof data === 'string' ? parseFloat(data) : data;

  return currencyFormat.format(amount);
}
