import React from "react";
import Box from "@mui/material/Box";
import mainLogo from "../assets/mainLogo-black.png";
import { Button, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component={"footer"}
      id='footer'
      position={"relative"}
      width={"100%"}
      bottom={0}
      display={"flex"}
      justifyContent='space-between'
      alignItems={"center"}
      margin={0}
      height={"228px"}
      sx={{
        flexDirection: { xs: "column", md: "row" },
        margin: { xs: "10px", md: 0 },
      }}
    >
      <Box sx={{ marginLeft: { xs: 0, md: "100px" } }}>
        <a href='../../../../../../'>
          <img alt='' src={mainLogo} style={{ height: "55px", width: "150px", color: "inherit" }} />
        </a>
        <Typography>Copyright © 2024, Blender Products, Inc. - All Rights Reserved</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end", // To justify content to the right
          alignItems: { xs: "flex-start", md: "flex-end" },
          marginRight: { xs: 0, md: "100px" },
        }}
      >
        <Typography fontFamily={"Montserrat Bold"} style={{ marginBottom: "0px" }}>
          ISO 9001: 2015 CERTIFIED*
        </Typography>
        <Typography style={{ fontSize: "70%" }}>
          *Design and manufacture of HVAC, Dust Collection & Industrial Ventilation Components & Equipment.
        </Typography>
        <Button type='button' variant='text' sx={{ textAlign: "right" }} href='tel:800.523.5705'>
          800.523.5705
        </Button>
        <Typography>
          <em>Blender Products, Inc. | 16600 E 33rd Drive, Unit #30 | Aurora, CO 80011</em>
        </Typography>
        <Typography fontSize={"8px"} color={"lightgray"}>
          version {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
