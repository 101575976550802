import { useEffect, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { PasswordField } from "../PasswordFiled";
import { SignUpData, useServerAPI } from "../../utils/Session";
import backgroundImage from "../../../src/assets/background-image.png";

interface Country {
  name: string;
  states: string[];
  code?: string;
}

const requiredFields: string[] = [
  "firstName",
  // "username",
  "password",
  "email",
  "phoneNumber",
  "address1",
  "country",
  "state",
  "zipCode",
  "city",
  "company",
  "repOrg"
];

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SignUpForm = () => {
  const serverAPI = useServerAPI();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<SignUpData>({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    company: "",
    phoneNumber: "",
    country: "United States",
    countryCode: "US",
    state: "",
    address1: "",
    address2: "",
    city: "",
    zipCode: "",
    repOrg: ""
  });

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/data/countries.json");
        setCountries(response.data);
        setStates(response.data.find((country: Country) => country.name === "United States")?.states || []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCountryChange = (selectedCountryCode: string) => {
    const selectedCountryData: any = countries.find((country: Country) => country.name === selectedCountryCode);

    if (selectedCountryData) {
      setStates(selectedCountryData.states || []);
    } else {
      setStates([]);
    }
  };

  const validateField = (fieldName: string, value: string) => {
    let error =
      value.trim() === ""
        ? `${fieldName.charAt(0).toUpperCase() + fieldName.replace(/([a-z])([A-Z])/g, "$1 $2").slice(1)} is required`
        : "";
    if (fieldName === "email" && error === "" && !EMAIL_REGEX.test(value)) {
      error = "Email is invalid";
    }
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [fieldName]: error,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, required } = e.target;
    setFormData({ ...formData, [name]: value });
    if (required) validateField(name, value);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    if (name === "country") {
      // set the country Code so that Braincloud shows the right one
      const countryCode = countries.find(c => c.name === value)?.code ?? "";
      handleCountryChange(value);
      setFormData({
        ...formData,
        [name]: value,
        countryCode,
        state: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    validateField(name, value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value, required } = e.target;
    if (required || name === "state") validateField(name, value);
  };

  const isFormValid = () => {
    return Object.keys(formData).some((key) =>
      requiredFields.includes(key) ? formErrors[key] || formData[key].trim() === "" : false
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsBusy(true);
    try {
      // Hack to make username and email the same
      formData.username = formData.email;
      await serverAPI.signUp(formData);
      setIsBusy(false);
      toast.success(<Typography>Account has been created</Typography>);
      navigate("/");
    } catch (e: any) {
      setIsBusy(false);
      toast.error(<Typography>{e?.message}</Typography>);
    }
  };

  return (
    <Grid
      container
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flex: "1 1 auto",
      }}
    >
      <Paper
        sx={{
          padding: "35px",
          margin: "24px 0 24px 100px",
        }}
      >
        <Typography variant='h5' component='h2'>
          Create Account
        </Typography>
        {!loading && (
          <form onSubmit={handleSubmit}>
            <Grid container direction='column' gap={1} sx={{ paddingTop: "20px" }}>
              <Grid container direction='column'>
                <Grid container direction={"row"} justifyContent={"space-around"} display={"flex"} spacing={2}>
                  <Grid item xs={6}>
                    {/* <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='username'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        Username *
                      </InputLabel>
                      <TextField
                        id='username'
                        name='username'
                        fullWidth
                        variant='outlined'
                        value={formData.username}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.username}
                        helperText={formErrors.username}
                      />
                    </Grid> */}
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='email'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        Email *
                      </InputLabel>
                      <TextField
                        id='email'
                        name='email'
                        fullWidth
                        variant='outlined'
                        value={formData.email}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!formErrors.email}
                        helperText={formErrors.email}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='password'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Password
                      </InputLabel>
                      <PasswordField
                        id='password'
                        name='password'
                        fullWidth
                        variant='outlined'
                        value={formData.password}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.password}
                        helperText={formErrors.password}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='firstName'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        First Name
                      </InputLabel>
                      <TextField
                        id='firstName'
                        name='firstName'
                        fullWidth
                        variant='outlined'
                        value={formData.firstName}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.firstName}
                        helperText={formErrors.firstName}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='lastName'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Last Name
                      </InputLabel>
                      <TextField
                        id='lastName'
                        name='lastName'
                        fullWidth
                        variant='outlined'
                        value={formData.lastName}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.lastName}
                        helperText={formErrors.lastName}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='company'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Company
                      </InputLabel>
                      <TextField
                        id='company'
                        name='company'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={formData.company}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.company}
                        helperText={formErrors.company}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='phoneNumber'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Phone Number
                      </InputLabel>
                      <TextField
                        id='phoneNumber'
                        name='phoneNumber'
                        type='new-password'
                        fullWidth
                        variant='outlined'
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.phoneNumber}
                        helperText={formErrors.phoneNumber}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='repOrg'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Rep Organization
                      </InputLabel>
                      <TextField
                        id='repOrg'
                        name='repOrg'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={formData.repOrg}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.repOrg}
                        helperText={formErrors.repOrg}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {/* address */}
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='address1'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Address1
                      </InputLabel>
                      <TextField
                        id='address1'
                        name='address1'
                        fullWidth
                        variant='outlined'
                        value={formData.address1}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required={true}
                        error={!!formErrors.address1}
                        helperText={formErrors.address1}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='address2'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        Address2
                      </InputLabel>
                      <TextField
                        id='address2'
                        name='address2'
                        fullWidth
                        variant='outlined'
                        value={formData.address2}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        error={!!formErrors.address2}
                        helperText={formErrors.address2}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='country'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        Country
                      </InputLabel>
                      <Select
                        id='country'
                        name={"country"}
                        value={formData.country}
                        required
                        fullWidth
                        onChange={handleSelectChange}
                        onBlur={handleBlur}
                        error={!!formErrors.country}
                      >
                        {countries.map((country) => (
                          <MenuItem value={country.name} key={country.name}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='country'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                        required
                      >
                        State / Province
                      </InputLabel>
                      <Select
                        id='state'
                        name={"state"}
                        value={formData.state}
                        required
                        fullWidth
                        onChange={handleSelectChange}
                        onBlur={handleBlur}
                        error={!!formErrors.state}
                      >
                        {states.map((state) => (
                          <MenuItem value={state} key={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      {formErrors.state && (
                        <Typography color={"error"} fontSize={"12px"} marginTop={"3px"} marginLeft={"14px"}>
                          {formErrors.state}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='password'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        City *
                      </InputLabel>
                      <TextField
                        id='city'
                        name='city'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={formData.city}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.city}
                        helperText={formErrors.city}
                      />
                    </Grid>
                    <Grid item height={"75px"}>
                      <InputLabel
                        htmlFor='password'
                        sx={{
                          fontFamily: "Montserrat Bold",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        ZIP Code *
                      </InputLabel>
                      <TextField
                        id='zipCode'
                        name='zipCode'
                        type='text'
                        fullWidth
                        variant='outlined'
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                        error={!!formErrors.zipCode}
                        helperText={formErrors.zipCode}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Divider sx={{ marginBottom: "12px", marginTop: "6px" }} />

                <Grid container>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    sx={{ width: "50%" }}
                    disabled={isFormValid() || isBusy}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    type='button'
                    variant='text'
                    sx={{ width: "50%" }}
                    disabled={isBusy}
                    onClick={() => navigate("/login")}
                  >
                    Log In
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <Typography fontSize={"12px"} marginTop={"3px"}>
                  Note: Your account need to be approved before you can log in.
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </Grid>
  );
};

export default SignUpForm;
