import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useRef } from 'react'
import PrintSummary from '../pages/ChannelBlenderJob/PrintSummary';
import { useAuth } from '../utils/Authentication';
import { useReactToPrint } from 'react-to-print';
import { LabelType } from '../types/Label.type';
import { CalculatorLabelsType } from '../types/CalculatorLabel.type';


interface SummaryDialogProps {
    open: boolean;
    onClose: () => void;
    jobInfo?: any;
    jobDetail?: any & { Input: any };
    valueFormatter: (label: LabelType, result: any) => string;
    channelBlenderLabels?: CalculatorLabelsType;

}

export default function SummaryDialog({ open, onClose, jobDetail, jobInfo, valueFormatter, channelBlenderLabels }: SummaryDialogProps) {

    const { userData } = useAuth();

    const componentRef = useRef(null);
    /*
    @font-face {
      font-family: "Montserrat Bold";
      src: local("Montserrat Bold"),
        url("./fonts/Montserrat-Bold.ttf") format("truetype");
    }
    
    @font-face {
      font-family: "Montserrat Light";
      src: local("Montserrat Light"),
        url("./fonts/Montserrat-Light.ttf") format("truetype");
    }
    
    @font-face {
      font-family: "Montserrat Regular";
      src: local("Montserrat Regular"),
        url("./fonts/Montserrat-Regular.ttf") format("truetype");
    }
    */

    const handlePrint = useReactToPrint({
        documentTitle: `Blender Preliminary Design - ${jobInfo?.name}`,
        removeAfterPrint: true,
    });


    return (
        <Dialog open={open} onClose={onClose} maxWidth='lg' >
            <DialogContent sx={{ width: '8.5in' }}>
                <PrintSummary
                    ref={componentRef}
                    jobInfo={jobInfo}
                    jobDetail={jobDetail}
                    valueFormatter={valueFormatter}
                    channelBlenderLabels={channelBlenderLabels}
                    userData={userData ?? null}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Cancel</Button>
                <Button
                    variant='contained'
                    color='primary'
                    className="no-print"
                    // disabled={isBusy || !selectedOptionId}
                    onClick={() => handlePrint(null, function () {
                        return componentRef.current;
                    })}
                // onClick={()=> useReactToPrint({content: () => componentRef.current,})}
                >
                    Print
                </Button>
            </DialogActions>
        </Dialog>
    )
}
