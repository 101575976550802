import React, { useState } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Paper, TextField, Typography } from "@mui/material";

import { useServerAPI } from "../../utils/Session";
import backgroundImage from "../../../src/assets/background-image.png";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ResetPassword = () => {
  const navigate = useNavigate();
  const serverAPI = useServerAPI();

  const [email, setEmail] = useState<string>("");
  const [error, setErrors] = useState<string | null>(null);

  const handleEmailChange = (event: any) => {
    const { value } = event.target;
    setErrors(null);
    setEmail(value);
  };

  const validateEmail = () => {
    let isValid = true;
    let errorMessage = null;

    if (email.trim().length === 0) {
      errorMessage = "Required";
      isValid = false;
    } else if (!EMAIL_REGEX.test(email)) {
      errorMessage = "Please enter a valid email";
      isValid = false;
    }

    setErrors(errorMessage);

    return { isValid };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { isValid } = validateEmail();
    if (!isValid) return;
    try {
      await serverAPI.resetEmailPassword({ email: email.trim() });
      toast.success("Password reset instructions sent to your email");
      navigate("/login");
    } catch (e: any) {
      toast.error(e?.message);
    }
  };

  return (
    <Grid
      container
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flex: "1 1 auto",
        minHeight: "100%",
      }}
    >
      <Paper
        sx={{
          width: "340px",
          padding: "35px 35px 15px 35px",
          marginLeft: "100px",
        }}
      >
        <Typography variant='h5' component='h2'>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container direction='column' sx={{ padding: "20px 0" }} spacing={2}>
            <Grid item>
              <TextField
                id='username'
                name='username'
                type='username'
                fullWidth
                variant='outlined'
                value={email}
                onChange={handleEmailChange}
                helperText={error}
                placeholder={"email@example.com"}
                error={error != null}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: "12px" }}>We will send a password reset email to this address.</Typography>
            </Grid>
            <Grid item display={"flex"}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                style={{ marginRight: "8px" }}
                sx={{ width: "50%" }}
                disabled={!email}
              >
                Submit
              </Button>
              <Button type='button' color='secondary' onClick={() => navigate("/login")} sx={{ width: "50%" }}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default ResetPassword;
