import { Box, Paper, Typography } from "@mui/material";
import { CalculatorLabelsType } from "../types/CalculatorLabel.type";

interface ChannelModelViewProps {
  property: string;
  input: any;
  channelBlenderLabels?: CalculatorLabelsType;
  direction?: "row" | "column"
}

export const ChannelModelView = ({ input, channelBlenderLabels, property, direction = "row" }: ChannelModelViewProps) => {

  if (input?.OrientationCode?.value && channelBlenderLabels && channelBlenderLabels[property]) {
    const renderImg = input?.SplitChannelBlender?.value
      ? channelBlenderLabels[property][input?.OrientationCode.value].doubleImg
      : channelBlenderLabels[property][input?.OrientationCode.value].singleImg;
    return (
      <>
        <Typography variant='h6'>
          Selected Orientation{" "}
          {direction === "column" ? <div /> : null}
          <strong>
            {channelBlenderLabels[property][input?.OrientationCode.value].label
              ? "  " + channelBlenderLabels[property][input?.OrientationCode.value].label
              : ""}
          </strong>
        </Typography>
        <Paper
          sx={{
            padding: "24px",
            // gap: 20,
            marginTop: "20px",
            marginBottom: "20px",
            display: "flex",
            position: 'sticky',
            top: '60px'
          }}>
          <Box
            sx={{ alignContent: "center", width: "100%" }}
            flexDirection={{ xs: "column", sm: direction }}
            display={"flex"}
            justifyContent={"space-around"}>
            <img alt={channelBlenderLabels[property][input?.OrientationCode.value].label}
              style={{ maxWidth: direction === "row" ? undefined : '100%', maxHeight: "258px", alignSelf: "center" }}
              src={`${channelBlenderLabels[property][input?.OrientationCode.value].boxImg}`}
              loading='lazy'
            />
            {renderImg?.toLowerCase().startsWith("http") && (
              <img alt={channelBlenderLabels[property][input?.OrientationCode.value].label} style={{ maxWidth: direction === "row" ? undefined : '100%', maxHeight: "258px", alignSelf: "center" }} src={`${renderImg}`} loading='lazy' />
            )}
          </Box>
        </Paper>
      </>
    );
  } else {
    return <div>ChannelModelView {input?.OrientationCode?.value}</div>;
  }
};
