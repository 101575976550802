import * as React from "react";

import {
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import Grid from "@mui/material/Grid";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useServerAPI } from "../../utils/Session";
import { useAuth } from "../../utils/Authentication";
import { CurrencyFormatter } from "../../utils/Currency";
import { ChartPoint, ChartSummaryItem } from "../../types/CharStats.type";

function JobInsightChart() {
  const serverApi = useServerAPI();
  const { userData } = useAuth();
  const showAirBlender = userData?.enableAirBlender;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const mounted = React.useRef(false);
  const [chartData, setChartData] = React.useState<ChartPoint[]>();
  const [pieChartData, setPieChartData] = React.useState<ChartSummaryItem[]>();

  const [pieChartWidth, setPieChartWidth] = React.useState(calculateChartWidth(0.4, isMd));
  const [barChartWidth, setBarChartWidth] = React.useState(calculateChartWidth(showAirBlender ? 0.6 : 1, isMd));

  const CHANNEL_COLOUR = "#6dc8fb";
  const AIR_COLOUR = "#0079c2";

  // Update the PieChart width when the window is resized
  React.useEffect(() => {
    function handleResize() {
      setPieChartWidth(calculateChartWidth(0.4, isMd));
      setBarChartWidth(calculateChartWidth(showAirBlender ? 0.6 : 1, isMd));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMd]);

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      const getJobInsight = () => {
        serverApi.getJobInsight().then((insightResult) => {
          mounted.current = true;
          if (insightResult.success) {
            setChartData(insightResult.response);
            setPieChartData(summarizeCommisions(insightResult.response));
          }
        });
      };

      getJobInsight();
    }
    return () => {
      // mounted.current = false;
    };
  }, [mounted.current]);

  const summarizeCommisions = (values: ChartPoint[]): ChartSummaryItem[] => {
    const channel = { blenderType: "Channel", commission: 0, fill: CHANNEL_COLOUR } as ChartSummaryItem;
    const air = { blenderType: "Air", commission: 0, fill: AIR_COLOUR } as ChartSummaryItem;
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      air.commission += element.EstimateAir ?? 0;
      channel.commission += element.EstimateChannel ?? 0;
    }
    return [channel, air];
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box component={'span'}
          className='custom-tooltip'
          sx={{
            backgroundColor: "#fffffff0",
            paddingX: "10px",
            paddingY: "2px",
            borderRadius: "5px",
            border: "1px solid lightGray",
          }}
        >
          {payload.map((v: any) => (
            <p className='label' key={v.name}>{`${v.name} : ${v.unit === "currency" ? CurrencyFormatter(v.value) : v.value
              }`}</p>
          ))}
        </Box>
      );
    }

    return null;
  };

  if (!userData?.showInsight) return null;

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography variant='h5' gutterBottom>
        Insights
      </Typography>
      <Card
        sx={{
          height: { xs: "auto", md: "416px" },
          justifyContent: "center",
          display: "flex",
          width: "100%",
        }}
        elevation={2}
      >
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          sx={{
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
            },
          }}
        >
          <Grid item md={showAirBlender ? 7 : 12}>
            <Box
              sx={{
                "& .recharts-legend-wrapper > ul": {
                  display: "flex !important",
                  justifyContent: "right",
                  flexDirection: "row",
                  gap: "5px",
                  "& li": {
                    display: "flex !important",
                    alignItems: "center",
                    "& svg": {
                      paddingRight: "5px",
                    },
                    "& span": {
                      fontSize: "12px",
                      fontFamily: "Montserrat Bold",
                      color: `${theme.palette.text.primary} !important`,
                    },
                  },
                },
                "& .recharts-cartesian-axis-tick > text > tspan": {
                  fontFamily: "Montserrat Bold",
                  fontSize: "12px",
                  top: "5px",
                },
              }}
            >
              <LineChart
                height={384}
                width={showAirBlender || !isMd ? barChartWidth : barChartWidth - 66}
                data={chartData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid
                  strokeDasharray='1 0'
                  vertical={false}
                  stroke={theme.palette.divider}
                  strokeWidth={0.5}
                />
                <Legend align={"right"} verticalAlign={"top"} height={36} iconType={"square"} iconSize={16} />
                <XAxis
                  dataKey='month'
                  tickCount={12}
                  tickLine={false}
                  axisLine={{
                    strokeDasharray: "1 0",
                    stroke: theme.palette.divider,
                    display: "none",
                  }}
                  padding={{ left: 15 }}
                  tick={{ dy: 10 }}
                />
                <YAxis yAxisId='left' tickLine={false} axisLine={false} tickFormatter={(a) => `$${a / 1000} k`} />
                <YAxis yAxisId='right' tickLine={false} axisLine={false} orientation='right' />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  yAxisId='right'
                  type={"linear"}
                  dataKey={"Channel"}
                  stroke={CHANNEL_COLOUR}
                  name={"Channel Blenders"}
                  dot={false}
                  strokeWidth={2}
                />
                {showAirBlender && (
                  <Line
                    yAxisId='right'
                    type={"linear"}
                    dataKey={"Air"}
                    stroke={AIR_COLOUR}
                    name={"Air Blenders"}
                    dot={false}
                    strokeWidth={2}
                  />
                )}
                <Line
                  yAxisId='left'
                  type={"linear"}
                  dataKey={"Estimate"}
                  stroke={"#90dd90"}
                  name={"Estimate"}
                  dot={false}
                  strokeWidth={2}
                  unit={"currency"}
                />
              </LineChart>
            </Box>
          </Grid>
          {showAirBlender && (
            <Grid item md={5}>
              <Box
                sx={{
                  "& .recharts-legend-wrapper > ul": {
                    display: "flex !important",
                    justifyContent: "right",
                    flexDirection: "column",
                    left: "75%",
                    position: "relative",
                    top: "100%",
                    gap: "15px",
                    "& li": {
                      display: "flex !important",
                      alignItems: "center",
                      "& svg": {
                        paddingRight: "5px",
                      },
                      "& span": {
                        fontSize: "12px",
                        fontFamily: "Montserrat Bold",
                        color: `${theme.palette.text.primary} !important`,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      },
                    },
                  },
                }}
              >
                <PieChart height={384} width={pieChartWidth}>
                  <Pie data={pieChartData} dataKey='commission' style={{ stroke: "none" }} fill='red'>
                    <LabelList
                      dataKey='blenderType'
                      position='inside'
                      style={{
                        fontSize: "18px",
                        fontFamily: "Montserrat Light",
                      }}
                    />
                    {pieChartData &&
                      pieChartData.map((entry, index) => {
                        return <Cell key={`cell-${index}`} fill={entry.fill} name={entry.blenderType}></Cell>;
                      })}
                  </Pie>
                  <Legend
                    align={"right"}
                    verticalAlign={"top"}
                    height={36}
                    iconType={"square"}
                    iconSize={16}
                    formatter={(value, entry) => {
                      return (
                        <>
                          <span>{value}</span>
                          <span>{CurrencyFormatter(entry.payload?.value)}</span>
                        </>
                      );
                    }}
                  />
                </PieChart>
              </Box>
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  );
}

export default JobInsightChart;

const calculateChartWidth = (percentage: number, isMd: boolean) => {
  const parentContainerWidth = window.innerWidth || document.documentElement.clientWidth;
  return isMd ? parentContainerWidth * percentage : parentContainerWidth - 66;
};
