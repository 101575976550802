import React, { useState } from "react";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, CircularProgress, Divider, Grid, InputLabel, Link, Paper, TextField, Typography } from "@mui/material";

import { useAuth } from "../../utils/Authentication";
import { useServerAPI } from "../../utils/Session/ServerAPI";
import backgroundImage from "../../../src/assets/background-image.png";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const serverAPI = useServerAPI();
  const { setAuthTokens } = useAuth();

  const { error } = location.state || {};

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string | undefined>(error?.message ?? undefined);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName: string, value: string) => {
    let error =
      value.trim() === ""
        ? `${fieldName.charAt(0).toUpperCase() + fieldName.replace(/([a-z])([A-Z])/g, "$1 $2").slice(1)} is required`
        : "";
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [fieldName]: error,
    }));
  };

  const validateForm = () => {
    const newFormErrors: Record<string, string> = {};

    if (formData.username.trim() === "") {
      newFormErrors.username = "Username is required";
    }

    if (formData.password.trim() === "") {
      newFormErrors.password = "Password is required";
    }

    setFormErrors(newFormErrors);
    return Object.keys(newFormErrors).length === 0;
  };

  const handleLogIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateForm()) return;

    setIsBusy(true);
    serverAPI
      .signIn({ ...formData })
      .then((result) => {
        setIsBusy(false);
        setAuthTokens(result.data.token);
        navigate("/");
      })
      .catch((error: AxiosError) => {
        setIsBusy(false);
        if (error.response) {
          const status = error?.response?.status;

          if (status === 401 || status === 403) {
            // Handle authentication errors
            setAuthError("Invalid Username or Password");
          }
          if (status === 405) {
            setAuthError("Account not approved yet");
          }
          if (status === 500) {
            setAuthError("Internal Server error");
          }
        } else if (error.request) {
          // The request was made but no response was received
          setAuthError("Check your network connection.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
        }
        console.error("Full error object:", error);
      });
  };

  return (
    <Grid
      container
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flex: "1 1 auto",
        minHeight: "100%",
      }}
    >
      <Paper
        sx={{
          width: "390px",
          padding: "35px 35px 0px 35px",
          marginLeft: "100px",
        }}
      >
        <Typography variant='h5' component='h2'>
          Welcome
        </Typography>
        <form onSubmit={handleLogIn}>
          <Grid container direction='column' spacing={2} sx={{ paddingTop: "20px" }}>
            <Grid item padding={0}>
              <InputLabel
                htmlFor='username'
                sx={{
                  fontFamily: "Montserrat Bold",
                  fontSize: "12px",
                  margin: "5px",
                }}
                required
              >
                Email
              </InputLabel>
              <TextField
                id='username'
                name='username'
                type='text'
                autoComplete='username'
                fullWidth
                autoFocus
                variant='outlined'
                value={formData.username}
                onChange={handleInputChange}
                error={!!formErrors.username}
                helperText={formErrors.username}
              />
            </Grid>
            <Grid item padding={0}>
              <InputLabel
                htmlFor='password'
                sx={{
                  fontFamily: "Montserrat Bold",
                  fontSize: "12px",
                  margin: "5px",
                }}
                required
              >
                Password
              </InputLabel>
              <TextField
                id='password'
                name='password'
                type='password'
                fullWidth
                variant='outlined'
                autoComplete='current-password'
                value={formData.password}
                onChange={handleInputChange}
                error={!!formErrors.password}
                helperText={formErrors.password}
              />
            </Grid>

            {authError && (
              <Typography color={"error"} sx={{ marginTop: "4px", alignSelf: "center", fontSize: "14px" }}>
                {authError}
              </Typography>
            )}

            <Grid item>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                sx={{ width: "50%", margin: "15px 0px" }}
                disabled={isBusy}
              >
                {isBusy && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: "absolute",
                      top: "auto",
                      left: "auto",
                    }}
                  />
                )}
                Log In
              </Button>

              <Button
                type='button'
                variant='text'
                sx={{ width: "50%", margin: "15px 0px" }}
                disabled={isBusy}
                onClick={() => navigate("/CreateAccount")}
              >
                Create Account
              </Button>

              <Divider sx={{ margin: "8px 0" }} />

              <Link
                underline={"hover"}
                sx={{
                  fontFamily: "Montserrat Bold",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/ResetPassword");
                }}
              >
                Request New Password
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default Login;
