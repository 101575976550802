import * as React from "react";

import {useNavigate} from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import {Grid} from "@mui/material";
import AppIcon from "../assets/mainLogo-white.png";
import {useAuth} from "../utils/Authentication";

const pages: string[] = ["Contact", "About", "Rep Locator", "Tools", "Products", "Appplications"];

function AppBar() {
  const {isAuthenticated, logout, userData} = useAuth();

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isIframe, setIsIframe] = React.useState(window.frames !== window.top?.frames);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    logout();
    navigate("/login");
  };

  if (isIframe) {
    if (isAuthenticated())
      return (
        <MuiAppBar
          position='sticky'
          className='main-window'
          sx={{
            boxShadow: "none",
            backgroundColor: "#03172b",
            height: "60px",
            // display: isIframe ? "none" : "inherit",
          }}>
          <Container maxWidth='xl' sx={{maxWidth: {lg: "100%"}}}>
            <Toolbar disableGutters sx={{height: "60px"}}>
              {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
              <Typography
                variant='h5'
                noWrap
                component='a'
                href='#app-bar-with-responsive-menu'
                sx={{
                  mr: 2,
                  display: {xs: "flex", md: "none"},
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}>
                <img alt='' src={AppIcon} height={"36px"} width={"96px"} />
              </Typography>
              {isAuthenticated() && (
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}>
                  <MenuItem onClick={(e) => handleOpenUserMenu(e)} disableRipple>
                    <Typography textAlign='center'>{userData?.username}</Typography>
                    <KeyboardArrowDownIcon color={"primary"} />
                  </MenuItem>
                  <Menu
                    sx={{mt: "45px", width: "100%"}}
                    id='menu-appbar'
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}>
                    <MenuItem onClick={() => handleLogout()} sx={{width: "100%"}}>
                      <Typography>Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Toolbar>
          </Container>
        </MuiAppBar>
      );
    else return null;
  }

  return (
    <MuiAppBar
      position='sticky'
      className='main-window'
      sx={{
        boxShadow: "none",
        backgroundColor: "#03172b",
        height: "60px",
      }}>
      <Container maxWidth='xl' sx={{maxWidth: {lg: "100%"}}}>
        <Toolbar disableGutters sx={{height: "60px"}}>
          <Box sx={{display: {xs: "none", md: "flex"}}}>
            <img alt='' src={AppIcon} height={"36px"} width={"96px"} />
          </Box>

          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          <Typography
            variant='h5'
            noWrap
            component='a'
            href='#app-bar-with-responsive-menu'
            sx={{
              mr: 2,
              display: {xs: "flex", md: "none"},
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}>
            <img alt='' src={AppIcon} height={"36px"} width={"96px"} />
          </Typography>

          {!isAuthenticated() && (
            <Box
              sx={{
                display: {xs: "flex", md: "none"},
              }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'>
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {xs: "block", md: "none"},
                  flexDirection: "row-reverse",
                  textTransform: "none",
                }}>
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign='center' textTransform={"none"}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          {!isAuthenticated() && (
            <Grid
              sx={{
                flexGrow: 1,
                display: {xs: "none", md: "flex"},
                flexDirection: "row-reverse",
              }}
              gap={6}>
              {pages.map((page) => (
                <Typography
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    textTransform: "none",
                    cursor: "pointer",
                  }}>
                  {page}
                </Typography>
              ))}
            </Grid>
          )}
          {isAuthenticated() && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "row-reverse",
              }}>
              <MenuItem onClick={(e) => handleOpenUserMenu(e)} disableRipple>
                <Typography textAlign='center'>{userData?.username}</Typography>
                <KeyboardArrowDownIcon color={"primary"} />
              </MenuItem>
              <Menu
                sx={{mt: "45px", width: "100%"}}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                <MenuItem onClick={() => handleLogout()} sx={{width: "100%"}}>
                  <Typography>Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
export default AppBar;
