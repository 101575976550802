import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";

import { ChannelModelView } from "../../components/ChannelModelView";
import { CalculateResponseType, CalculatorResultType } from "../../types/CaculateResponse.type";
import { CalculatorLabelsType } from "../../types/CalculatorLabel.type";
import { JobDetailsType, JobInfoType, JobStateType } from "../../types/JobInfo.type";
import { useAuth } from "../../utils/Authentication";
import { useServerAPI } from "../../utils/Session";
import Calculator from "./Calculator";
import Results from "./Results";
import { LayoutConfig } from "../../types/LayoutConfig.type";

type DisplayableResult = {
  ModelID: string;
  ChannelBlenderQty: number;
  Height: number;
  Width: number;
  Depth: number;
  Weight: number;
  RequiredActuatorQty: number;
  PressureDrop25pcOA: number;
  PressureDrop100pcOA: number;
  ListPrice: number;
  MixingBoxDepth: number;
  entityId: string;
  status: string;
  statusMessage: string;
};
const ChannelBlenderJob = () => {
  const { userData } = useAuth();
  const { state: locationState } = useLocation();
  const { entityId } = locationState || {};
  const serverAPI = useServerAPI();

  // State variables
  const [results, setResults] = useState<DisplayableResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [channelBlenderDefaults, setChannelBlenderDefaults] = useState<any>();
  const [channelBlenderLabels, setChannelBlenderLabels] = useState<CalculatorLabelsType | undefined>();
  const [channelBlenderLayout, setChannelBlenderLayout] = useState<LayoutConfig>();
  const [jobDetails, setJobDetails] = useState<CalculateResponseType<CalculatorResultType> | null>(null);
  const [jobState, setJobState] = useState<JobStateType | null>(null);
  const [currentInput, setCurrentInput] = useState<any>();
  const [latestJobInfo, setLatestHJobInfo] = useState<JobInfoType>();

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: defaults, labels, layout } = await serverAPI.getChannelBlenderDefaults({});
        setChannelBlenderDefaults(defaults);
        setChannelBlenderLabels(labels);
        setChannelBlenderLayout(layout);
        if (entityId) {
          const response = await serverAPI.getJobDetail(entityId);
          const jobDetails = response as CalculateResponseType<CalculatorResultType>;
          if (jobDetails) {
            const { result, jobInfo } = jobDetails;
            handleCalculatorData(jobInfo, result, jobDetails.entityId, jobDetails.options ?? []);
            setJobDetails(jobDetails);
            setJobState(jobDetails.jobInfo.jobState ?? null);
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  // Function to update the state with calculatedResult from Calculator
  const handleCalculatorData = (
    jobInfo: JobInfoType,
    calculatedResult: any,
    _entityId?: string,
    options?: JobDetailsType[]
  ) => {
    const formattedResults = [] as any;
    console.log('At calculation jobInfo:', jobInfo);
    setLatestHJobInfo(jobInfo);
    const isRequestingPartyBasisDesign =
      jobInfo.isRequestingPartyBasisDesign != null ? jobInfo.isRequestingPartyBasisDesign : false;

    formattedResults.push(
      mapResultData(isRequestingPartyBasisDesign, jobInfo, calculatedResult, _entityId ? _entityId : entityId)
    );

    if (Array.isArray(options)) {
      options.forEach((option, i) => {
        formattedResults.push(
          mapResultData(isRequestingPartyBasisDesign, option.jobInfo, option.result, option.entityId)
        );
      });
    }

    setResults(formattedResults);
  };

  // Function to create a copy of the current job
  const handleCreateCopy = () => {
    if (jobDetails) {
      const newJobDetails = { ...jobDetails };

      if (newJobDetails.result && newJobDetails.result.jobInfo) {
        newJobDetails.result.jobInfo.jobState = "New";
        newJobDetails.result.jobInfo.name = "";
      }

      setJobState("New");
      setResults([]);
      setJobDetails({ ...newJobDetails });
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Function to map result data
  const mapResultData = (
    isRequestingPartyBasisDesign: boolean,
    jobInfo: JobInfoType,
    result: any,
    _entityId?: string
  ) => {
    const isReseller = userData?.isReseller;
    const isOEM = userData?.isOEM;
    const isHoriz = result.BladeOrientation === "Horizontal";
    console.info(`Mapping Results isReseller:${isReseller}, isOEM:${isOEM} isHoriz:${isHoriz}`, result);
    return {
      ...result,
      OAOpeningSize: (result.OAOpeningWidth ?? 0) * (result.OAOpeningLength ?? 0),
      RAOpeningSize: (result.RAOpeningWidth ?? 0) * (result.RAOpeningLength ?? 0),
      ListPrice: isReseller
        ? jobInfo.resellerPrice
        : isOEM && !isRequestingPartyBasisDesign
          ? jobInfo.oemPrice
          : result.ListPrice,
      entityId: _entityId,
      status: result.Status,
      statusMessage: result.StatusMessage,
      A: result.Height - result.BottomChannelLength + 3,
      B: (result.Width - result.OAOpeningLength) / 2 + (isHoriz ? result.Input.BaseRailHeight : 0),
      C: (result.Width - result.OAOpeningLength) / 2,
    };
  };

  const handleInputChange = (value: any) => {
    console.debug('Current Input is ', value);
    setCurrentInput(value);
  };

  return (
    <Box sx={{ padding: "24px" }}>
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        display={"flex"}
        justifyContent={"space-between"}
        spacing={4}>
        <Grid item md={4}>
          <Calculator
            jobState={jobState}
            jobDetails={jobDetails}
            channelBlenderDefaults={channelBlenderDefaults}
            onPerformCalculation={handleCalculatorData}
            onCreateCopy={handleCreateCopy}
            channelBlenderLabels={channelBlenderLabels}
            layout={channelBlenderLayout}
            onFormValueChanged={handleInputChange}
          />
        </Grid>
        <Grid item md={3}>
          <ChannelModelView
            input={currentInput}
            channelBlenderLabels={channelBlenderLabels}
            property='OrientationCode'
            direction="column"
          />
        </Grid>
        <Grid item md={5}>
          <Results
            jobInfo={latestJobInfo ?? jobDetails?.jobInfo}
            jobState={jobState}
            onJobStateChange={setJobState}
            results={results}
            onDataUpdate={handleCalculatorData}
            channelBlenderLabels={channelBlenderLabels}
          />
        </Grid>
      </Grid>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color='primary' />
        </Backdrop>
      )}
    </Box>
  );
};

export default ChannelBlenderJob;
